import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Menu } from "semantic-ui-react";


const Navigation = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('/');
    const handleClick  = (e,{name}) => {
        setActiveItem(name);
        navigate(name)
    }

    return (

        <Menu stackable className="fixed">
            <Menu.Item>
                <p style={{ color: '#864fba', fontSize: 22, fontWeight: 'bold', fontFamily: "sans-serif" }}>CO.</p>
            </Menu.Item>

            <Menu.Item
                name='/'
                active={activeItem === '/'}
                onClick={handleClick}
            >
                Cek Ongkir
            </Menu.Item>

            <Menu.Item
                name='tracking'
                active={activeItem === 'tracking'}
                onClick={handleClick}
            >
                Tracking
            </Menu.Item>
            
            <Menu.Menu position="right">
                <Menu.Item style={{color:'#864fba'}}>&copy;2023. Developed by HS.</Menu.Item>
            </Menu.Menu>
        </Menu>
        
    )
}

export default Navigation;