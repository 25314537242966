import React from "react";
import { Container } from "semantic-ui-react";

const Tracking = () => {
    return (
        <Container className="content">
            <div style={{marginBottom:'20px'}}>
                <span style={{color:'#864fba', fontWeight:'bold', fontSize:'30px'}}>Tracking Resi </span>
                <span style={{color:'grey'}}>JNE</span>
            </div>
            Comming Soon...
        </Container>
    )
}

export default Tracking;