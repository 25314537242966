import logo from './logo.svg';
import './App.css';
import CekOngkir from './view/CekOngkir';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './view/Navigation';
import Tracking from './view/Tracking';


function App() {
  return (
    <div className="App">
      <Router>
        <Navigation/>
        <Routes>
          <Route path='/' element={<CekOngkir/>} />
          <Route path='/tracking' element={<Tracking />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
