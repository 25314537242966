import React, { useEffect, useState } from "react";
import { Container, Divider, Grid, Input, Label, SegmentGroup, Table } from "semantic-ui-react";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { Button, Form } from 'semantic-ui-react';
import { NumericFormat } from 'react-number-format';

const CekOngkir = () => {
    const [origin, setOrigin] = useState(null);
    const [desti, setDesti] = useState(null);
    const [weight, setWeight] = useState(1);
    const [kotaAsal, setKotaAsal] = useState(null);
    const [kotaAsalLabel, setKotaAsalLabel] = useState(null);
    const [kotaTujuan, setKotaTujuan] = useState(null);
    const [kotaTujuanLabel, setKotaTujuanLabel] = useState(null);
    const [tarif, setTarif] = useState(null);

    const originApi = async () => {
        const param = new URLSearchParams();
        let options = [];
        param.append('token', '7f19e77e76c4e6402385713e5f77488d');

        await axios({
            method: "POST",
            url: 'https://api.hoopsdevteam.com/cek_ongkir/?index=1',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res) {
                    res.data.detail.map((d, i) => {
                        options.push({
                            label: d.City_Name,
                            value: d.City_Code,
                        })
                    })

                    setOrigin(options);
                }
            })
    }

    const destiApi = async () => {
        const param = new URLSearchParams();
        let options = [];
        param.append('username', 'SPORTINDO');
        param.append('api_key', '8bfa0a15a3b4a644bea6ef38fcfeca6d');
        param.append('token', '7f19e77e76c4e6402385713e5f77488d');

        await axios({
            method: "POST",
            url: 'https://api.hoopsdevteam.com/cek_ongkir/?index=2',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res) {
                    res.data.detail.map((d, i) => {
                        options.push({
                            label: d.City_Name,
                            value: d.City_Code,
                        })
                    })

                    setDesti(options);
                }
            })
    }

    const tarifApi = async (from, thru, weight) => {
        const param = new URLSearchParams();
        param.append('from', from);
        param.append('thru', thru);
        param.append('weight', weight);
        param.append('token', '7f19e77e76c4e6402385713e5f77488d');

        await axios({
            method: "POST",
            url: 'https://api.hoopsdevteam.com/cek_ongkir/?index=3',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res.data.status !== false) {
                    setTarif(res.data.price.sort((a, b) => a.price - b.price));
                }
            })
    }

    const filterOrigin = (inputValue) => {
        if (origin)
            return origin.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
    };

    const filterDesti = (inputValue) => {
        if (desti)
            return desti.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
    };

    const promiseOptionsOrigin = (inputValue) => (
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterOrigin(inputValue));
            }, 1000);
        })
    );

    const promiseOptionsDesti = (inputValue) => (
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterDesti(inputValue));
            }, 1000);
        })
    );

    const handleSubmit = () => {
        if (weight > 0){
            if (kotaAsal){
                if (kotaTujuan){
                    tarifApi(kotaAsal, kotaTujuan, weight);
                }else{
                    alert('Mau dikirim kemana ini paket?... pilih dulu kota tujuan nya bos...')
                }
            }else{
                alert('Asal paketnya dari mana nih?... pilih dulu kota asalnya bos...')
            }
        }else{
            alert('Berat minimal 1 kg ya.. awas jangan di kosongin juga');
        }
    }

    useEffect(() => {
        originApi();
        destiApi();
    }, []);

    return (
        <Container className="content">
            <div style={{marginBottom:'15px'}}>
                <span style={{ color: '#864fba', fontWeight: 'bold', fontSize: '30px' }}>Cek Ongkir</span>
                <span style={{color:'grey'}}>JNE</span>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form onSubmit={() => handleSubmit()}>
                            <Form.Field>
                                <label style={{color:'#864fba'}}>Kota Asal</label>
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    defaultOptions={origin}
                                    loadOptions={promiseOptionsOrigin}
                                    onChange={(value, action) => {
                                        setKotaAsal((value) ? value.value : null);
                                        setKotaAsalLabel((value) ? value.label : '');
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{color:'#864fba'}}>Kota Tujuan</label>
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    defaultOptions={desti}
                                    loadOptions={promiseOptionsDesti}
                                    onChange={(value, action) => {
                                        setKotaTujuan((value) ? value.value : null);
                                        setKotaTujuanLabel((value) ? value.label : '');
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{color:'#864fba'}}>Berat Paket</label>
                                <Input label={{ basic: true, content: 'kg' }} type="number" labelPosition="right" defaultValue={weight} onChange={e => setWeight(e.target.value)} />
                            </Form.Field>
                            <Button content="Hitung Tarif" type="submit" size="large" style={{ background: "#864fba", color: "#fff" }} />
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Divider horizontal style={{ color: '#864fba', fontWeight: 'bold' }}>hasil perhitungan tarif</Divider>
                <Grid.Row>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row columns={3} style={{ textTransform: 'uppercase' }}>
                                <Grid.Column width={5} style={{ marginRight: 30 }}><div style={{ color: '#b0b0b0', fontWeight: 'bold' }}>Kota Asal :</div>{kotaAsalLabel}</Grid.Column>
                                <Grid.Column width={5} style={{ marginRight: 30 }}><div style={{ color: '#b0b0b0', fontWeight: 'bold' }}>Kota Tujuan :</div>{kotaTujuanLabel}</Grid.Column>
                                <Grid.Column width={2} style={{ marginRight: 30 }}><div style={{ color: '#b0b0b0', fontWeight: 'bold' }}>Berat Paket :</div>{weight} kg</Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Table celled>
                            <Table.Header>
                                <Table.Row >
                                    <Table.HeaderCell style={{color:'#864fba'}}>Layanan</Table.HeaderCell>
                                    <Table.HeaderCell style={{color:'#864fba'}}>Estimasi</Table.HeaderCell>
                                    <Table.HeaderCell style={{color:'#864fba'}}>Biaya</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    tarif &&
                                    tarif.map((item, i) =>
                                        <Table.Row>
                                            <Table.Cell>{item.service_display}</Table.Cell>
                                            <Table.Cell>{item.etd_from}-{item.etd_thru}  hari</Table.Cell>
                                            <Table.Cell>
                                                <NumericFormat value={item.price} displayType='text' thousandSeparator={true} prefix="Rp. " />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default CekOngkir;